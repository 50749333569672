// src/components/PricingCard.js
import React from 'react';
import PropTypes from 'prop-types';
import './PricingCard.css';

function PricingCard({ id, title, text, image, link }) {
  const cardContent = (
    <div className="pricing-card-content">
      <img src={image} alt={title} className="pricing-card-image" />
      <div className="pricing-card-details">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );

  return (
    <div className="pricing-card" id={id}>
      {link ? (
        <a href={link} className="pricing-card-link">
          {cardContent}
        </a>
      ) : (
        cardContent
      )}
    </div>
  );
}

PricingCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default PricingCard;

{/*return (
    <div className="pricing-card" id={id}>
      <img src={image} alt={title} className="pricing-card-image" />
      <div className="pricing-card-content">
        <h3>{title}</h3>
        <p>{text}</p>
        {link && (
          <a href={link} className="pricing-card-link">
            En savoir plus
          </a>
        )}
      </div>
    </div>
  );*/}