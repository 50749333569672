// src/components/Section_si.js
import React from 'react';
import './Section.css';

const Section_si = ({ id, title, text, children }) => {
  return (
    <section_si id={id} className="section_si">
      <h2>{title}</h2>
      {text && <div className="section-text">{text}</div>}
      {children}
    </section_si>
  );
};

export default Section_si;
