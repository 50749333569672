// src/components/Footer.js
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import './Footer.css';
import logo from '../assets/toco_bg.png';
import { FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="footer-left">
          <p>2024 - Tous droits réservés</p>
        </div>
        <div className="footer-right">
          <a href="https://www.instagram.com/oreades.io/" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
