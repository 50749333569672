// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/toco_bg.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCloseMenu = () => {
  setMenuOpen(false);
};

  const handleLogout = () => {
    // Remplacez ceci par votre logique de déconnexion
    setIsAuthenticated(false);
    setMenuOpen(false); // Fermer le menu déroulant après la déconnexion
  };

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="header-center">
        <nav className="menu">
          <ul className={menuOpen ? 'open' : ''}>
            <li>
              <Link to="/formations">📚 Formation</Link>
            </li>
            <li>
              <Link to="/ressources">🧰 Ressources</Link>
            </li>
            <li>
              <Link to="https://calendly.com/donovan-dimanche/60min">📞 Entretien téléphonique</Link>
            </li>
            
            {/*<div className="menu-divider"></div>
            <li className="auth-buttons">
              {isAuthenticated ? (
                <>
                  <Link to="/mon-espace">
                    <button>Mon profil</button>
                  </Link>
                  <button onClick={handleLogout}>Déconnexion</button>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <button className="btn-login">Connexion</button>
                  </Link>
                  <Link to="/signup">
                    <button className="btn-signup">Inscription</button>
                  </Link>
                </>
              )}
            </li>*/}
          </ul>
           {/*<div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {menuOpen ? (
              <>
                <div className="close-icon" onClick={handleCloseMenu}>&times;</div>
              </>
            ) : (
              <>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </>
            )}
          </div>*/}
        </nav>
      </div>
    </header>
  );
};

export default Header;
