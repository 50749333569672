import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Section_aig from './components/Section_aig.js';
import Section_aid from './components/Section_aid.js';
import Section_si from './components/Section_si';
import Footer from './components/Footer';
import FooterMenu from './components/FooterMenu';
import Signup from './components/Signup';
import Login from './components/Login';
import MonEspace from './components/MonEspace';
import PrivateRoute from './components/PrivateRoute';

import Formations from './pages/formations';
import Ressources from './pages/ressources';
import MentionsLegales from './pages/MentionsLegales';
import CGV from './pages/CGV';
import Confidentialite from './pages/Confidentialite';

import methodesImage1 from './assets/images/chess.jpg';
import methodesImage3 from './assets/images/entretien-individuel-fun.png';
import methodesImage4 from './assets/images/watering-woman.jpg';
import methodesImage2 from './assets/images/img_invest.png';

const methodesText1 = (
  <>
  <p>Vous souhaitez découvrir le fonctionnement des <strong>marchés boursiers</strong>, connaitre les différents courtiers et les outils disponibles.</p>
  <p>Ou encore apprendre ce qui détermine le prix d'une action et explorer les différents instruments financiers, les intérêts composés et les stratégies d’investissement.</p>
  <p>Enfin vous n'arrivez pas à choisir <strong>l'enveloppe fiscale</strong> idéale pour vos investissements. Faisons le point entre un <strong>PEA</strong>, un <strong>CTO</strong> ou encore <strong>Assurance-vie</strong>.</p>
  <p>A très vite !</p>
  <button onClick={() => window.location.href="/formations"}> Accéder au contenu de formations </button>
  </>
);

const methodesText2 = (
  <>
    <div className="methodes-part">
      <p>Optez pour la réussite en adoptant des stratégies validées.</p>
      <button onClick={() => window.location.href="/ressources"}> En savoir plus </button>
      <p> Si vous souhaitez accéder à la newsletters, inscrivez vous au lien suivant :</p>
      <button onClick={() => window.location.href="/ressources"}> Abonnez-vous </button>
    </div>
  </>
);

const methodesText3 = (
  <>
  <p>Un entretien téléphonique de 45 min pour échanger sur votre stratégie d'investissement :</p>
  <p></p>👍 Répondre à toutes <strong> vos questions</strong>, spécifiques ou générales 
  <p>🎯 Voir ensemble <strong> votre portefeuille</strong>, revue de vos positions et stratégie globale</p>
  <button onClick={() => window.open('https://calendly.com/donovan-dimanche/60min', '_blank')}>Prendre RDV</button>
  </>
);

function Home () {
  return (
      <main>
        <Section_aid 
          id="formations" 
          title="📚 Formations"
          text={methodesText1}
          image={methodesImage4}
          >
        </Section_aid>
        <div className="footer-divider-two"></div>
        <Section_aig 
          id="ressources" 
          title="🧰 Ressources"
          image={methodesImage1}
          text={methodesText2}
          >
        </Section_aig>
        <div className="footer-divider-two"></div>
        <Section_aid
          id="methodes"
          title="📞 Entretien téléphonique"
          text={methodesText3}
          image={methodesImage3}
         > 
        </Section_aid>
      </main>
  );
};

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/formations" element={<Formations />} />
            <Route path="/ressources" element={<Ressources />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/mon-espace/*"
              element={
                <PrivateRoute>
                  <MonEspace />
                </PrivateRoute>
              }
            />
            <Route path="/MentionsLegales" element={<MentionsLegales />} />
            <Route path="/CGV" element={<CGV />} />
            <Route path="/Confidentialite" element={<Confidentialite />} />
          </Routes>
        </main>
        <FooterMenu />
        <div className="footer-divider-two"></div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
