// src/pages/CGV.js
import React from 'react';
import Section_si from '../components/Section_si';

const methodesTextMLT = (
  <>
    <div className="methodes-part">
      <p>Les présentes conditions générales de vente (CGV) régissent les ventes de services réalisées sur le site de Hol'Don. En validant sa commande, le client déclare accepter sans réserve les termes de celles-ci.</p>
    </div>
  </>
);

const methodesTextMLC = (
  <>
    <div className="methodes-part">
      <p>Les paiements se font par carte bancaire via notre prestataire de paiement sécurisé. Les informations de paiement ne sont pas conservées par notre entreprise.</p>
      <h3>Livraison des Services</h3>
      <p>Les services sont délivrés immédiatement après la confirmation de la commande, sauf indication contraire sur le site.</p>
      <h3>Droit de Rétractation</h3>
      <p>Conformément à la législation en vigueur, le client dispose d'un délai de 14 jours pour exercer son droit de rétractation.</p>
    </div>
  </>
);

const CGV = () => {
  return (
    <>
        <Section_si 
          id="cgv" 
          title="Conditions Générales de Vente"
          text={methodesTextMLT}
          >
        </Section_si>
        <Section_si 
          id="cgv" 
          title="Modalités de Paiement"
          text={methodesTextMLC}
          >
        </Section_si>
      </>
  );
};

export default CGV;
