// Importation des fonctionnalités Firebase nécessaires
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Configuration de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD2vaU7LNvvrxvZf_cDMHCH13CQeag1Fh4",
  authDomain: "still-bastion-16604.firebaseapp.com",
  projectId: "still-bastion-16604",
  storageBucket: "still-bastion-16604.appspot.com",
  messagingSenderId: "32270080766",
  appId: "1:32270080766:web:b1c4e8a722c7970256a3af",
  measurementId: "G-67SV23JWQC"
};

// Initialisation de l'application Firebase
const app = initializeApp(firebaseConfig);

// Obtention de l'instance Auth pour l'authentification
export const auth = getAuth(app);
