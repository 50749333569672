// src/components/FooterMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import './FooterMenu.css';
import logo from '../assets/toco_bg.png';

function FooterMenu() {
  return (
    <footerMenu className="footer">
      <div className="footer-column">
        <Link to="/">
          <img src={logo} alt="Logo" className="footer-logo" />
        </Link>
      </div>
      <div className="footer-column">
        <h3>Plan du site</h3>
        <ul className="footer-links">
          <li><a href="../formations">Formations</a></li>
          <li><a href="../ressources">Ressources</a></li>
          <li><a href="https://calendly.com/donovan-dimanche/60min">Entretien individuel</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Liens utiles</h3>
        <ul className="footer-links">
          <li><a href="../MentionsLegales"> Mentions Légales</a></li>
          <li><a href="../CGV"> CGV</a></li>
          <li><a href="../Confidentialite"> Confidentialité</a></li>
        </ul>
      </div>
    </footerMenu>
  );
}

export default FooterMenu;
