// src/pages/Formations.js
import React from 'react';

import Section_aig from '../components/Section_aig';
import Section_aid from '../components/Section_aid';
import Section_si from '../components/Section_si';

import methodesImage1 from '../assets/images/entretien-individuel.svg';
import methodesImage2 from '../assets/images/gardening.webp';
import imgJournal from '../assets/images/journalInvest.png';

import PricingCard from '../components/PricingCard';
import PricingSliderFormation from '../components/PricingSliderFormation';

const methodesText1 = (
  <>
  <p><strong>Investir</strong> est une étape cruciale dans la vie, nécessitant une grande dose d'<strong>envie</strong> et de <strong>patience</strong> pour y parvenir.</p>
  <p>Avec beaucoup de <strong>motivation</strong> et une <strong>formation de qualité</strong>, chacun peut réussir dans ce domaine.</p>
  <p>Être bien <strong>accompagné</strong> dans l'investissement, c'est s'assurer de mettre toutes les chances de son côté pour réussir.</p>
  </>
);

const methodesText2 = (
  <>
    <div className="methodes-part">
      <p>Je propose un barème de notation des actions afin de pouvoir choisir consciencieusement des actions. Analyser une action avant de l'acheter en bourse nécessite une approche méthodique et détaillée pour comprendre à la fois les perspectives de l'entreprise et le contexte économique plus large. Voici les étapes clés que vous devriez suivre pour effectuer une analyse complète :</p>

      <h3>1. Analyse Fondamentale</h3>
      <table>
        <thead>
          <tr>
            <th>Nom du Critère</th>
            <th>Explications</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ratio cours/bénéfice (P/E)</td>
            <td>Évalue la valorisation de l'entreprise en comparant le cours de l'action à son bénéfice par action.</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Ratio cours/valeur comptable (P/B)</td>
            <td>Compare la valeur comptable de l'entreprise à son cours de bourse pour évaluer sa valeur intrinsèque.</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Rendement du dividende</td>
            <td>Mesure le rendement des dividendes versés par l'entreprise par rapport au cours de l'action.</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Ratio dette/capitaux propres</td>
            <td>Évalue le niveau d'endettement de l'entreprise par rapport à ses capitaux propres.</td>
            <td>2</td>
          </tr>
        </tbody>
      </table>

      <h3>2. Analyse Qualitative</h3>
      <table>
        <thead>
          <tr>
            <th>Nom du Critère</th>
            <th>Explications</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Modèle économique et avantage concurrentiel</td>
            <td>Évalue la solidité du modèle économique de l'entreprise et son avantage concurrentiel.</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Gouvernance et gestion</td>
            <td>Examine la qualité de la gestion et la structure de gouvernance de l'entreprise.</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Risques spécifiques à l'entreprise</td>
            <td>Identifie les risques potentiels qui pourraient affecter l'entreprise.</td>
            <td>2</td>
          </tr>
        </tbody>
      </table>

      <h3>3. Analyse du Secteur et du Marché</h3>
      <table>
        <thead>
          <tr>
            <th>Nom du Critère</th>
            <th>Explications</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Position dans le secteur</td>
            <td>Évalue la position de l'entreprise dans son secteur et son industrie.</td>
            <td>3</td>
          </tr>
          <tr>
            <td>Tendances du secteur</td>
            <td>Identifie les grandes tendances qui pourraient affecter le secteur.</td>
            <td>2</td>
          </tr>
          <tr>
            <td>Comparaison sectorielle</td>
            <td>Compare l'entreprise à ses concurrents pour comprendre sa position relative.</td>
            <td>2</td>
          </tr>
        </tbody>
      </table>

      <h3>4. Analyse Technique</h3>
      <p>
        <strong>Tendances des prix :</strong> Utilisez l'analyse technique pour examiner les tendances des prix et des volumes de l'action. Cela peut inclure l'analyse de graphiques, l'utilisation d'indicateurs comme les moyennes mobiles, le RSI (Relative Strength Index), et les bandes de Bollinger.
        <br />
        <strong>Patterns graphiques :</strong> Identifiez les patterns dans les mouvements de prix qui pourraient indiquer des points d'entrée ou de sortie potentiels.
      </p>

      <h3>5. Contexte Macroéconomique</h3>
      <p>
        <strong>Conditions économiques :</strong> Prenez en compte l'état de l'économie globale, y compris les taux d'intérêt, l'inflation, et le PIB. Ces facteurs peuvent affecter les performances des entreprises et des marchés.
        <br />
        <strong>Facteurs géopolitiques :</strong> Soyez conscient des événements géopolitiques qui pourraient affecter l'entreprise ou son secteur.
      </p>

      <h3>6. Synthèse et Décision</h3>
      <p>
        <strong>Synthétiser les données :</strong> Intégrez les données de l'analyse fondamentale, qualitative, sectorielle, technique, et macroéconomique pour former une vue complète de l'investissement potentiel.
        <br />
        <strong>Prise de décision :</strong> Décidez d'acheter, de conserver, ou de vendre l'action basé sur votre analyse. Assurez-vous que l'investissement s'aligne avec vos objectifs financiers et votre tolérance au risque. Ces étapes vous aideront à faire un choix éclairé et à minimiser les risques associés à vos investissements en actions.
      </p>
    </div>
  </>
);

const methodeJournalInvestisseur = (
  <>
    <div className="methodes-part">
      <p>Abonnez-vous dès à présent et téléchargez le guide du journal d'investisseur.</p>
      <button onClick={() => window.location.href="/ressources"}> Abonnez-vous </button>
    </div>
  </>
);

const Formations = () => {
  return (
    <>
        <Section_aid 
          id="formations" 
          title="Investir est à la portée de tous"
          text={methodesText1}
          image={methodesImage2}
          >
        </Section_aid>
        <div>
          <h2>📚 Apprenez à investir</h2>
          <p>Voici les livres à absolument posséder dans sa collection pour que l'investissement n'ait plus aucun secret pour vous.</p>
          <PricingSliderFormation />
        </div>
        <div className="footer-divider-two"></div>
        <Section_si 
          id="ressources" 
          title="🔎 Méthode pour analyser une action"
          text={methodesText2}
          >
        </Section_si>
        <div className="footer-divider-two"></div>
        <Section_aig 
          id="formations" 
          title="🗞 Télécharge le journal de l'investisseur"
          text={methodeJournalInvestisseur}
          image={imgJournal}
          >
        </Section_aig>
      </>
  );
};

export default Formations;
