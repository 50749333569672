// src/pages/Confidentialite.js
import React from 'react';
import Section_si from '../components/Section_si';

const methodesTextMLT = (
  <>
    <div className="methodes-part">
      <p>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>
    </div>
  </>
);

const methodesTextMLC = (
  <>
    <div className="methodes-part">
      <p>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section Politique de confidentialité, conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>
    </div>
  </>
);

const Confidentialite = () => {
  return (
    <>
        <Section_si 
          id="confidentialite" 
          title="Politique de Confidentialité"
          text={methodesTextMLT}
          >
        </Section_si>
        <Section_si 
          id="confidentialite" 
          title="Données personnelles"
          text={methodesTextMLC}
          >
        </Section_si>
      </>
  );
};

export default Confidentialite;
