// src/components/PricingSliderFormation.js
import React from 'react';
import Slider from 'react-slick';

import PricingCard from './PricingCard';
import './PricingSlider.css';

import methodesImage1 from '../assets/images/atmf.jpg';
import methodesImage2 from '../assets/images/blackSwan.jpg';
import methodesImage3 from '../assets/images/rwdws.jpg';
import methodesImage4 from '../assets/images/titz.jpg';

function PricingSliderFormation() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="pricing-slider">
      <Slider {...settings}>
        <PricingCard
          id="methode1"
          title="Analyse technique des marchés financiers"
          text="Analyse technique des marchés financiers"
          image={methodesImage1}
        />
        <PricingCard
          id="methode2"
          title="The Black Swan"
          text="The Black Swan"
          image={methodesImage2}
        />
        <PricingCard
          id="methode3"
          title="A Random Walk Down Wall Street"
          text="A Random Walk Down Wall Street"
          image={methodesImage3}
        />
        <PricingCard
          id="methode4"
          title="Trading in the Zone"
          text="Trading in the Zone"
          image={methodesImage4}
        />
        {/*<PricingCard
          id="methode5"
          title="Méthode 5"
          text="Description de la méthode 5"
          image={methodesImage1}
        />
        <PricingCard
          id="methode6"
          title="Méthode 6"
          text="Description de la méthode 6"
          image={methodesImage2}
        />*/}
      </Slider>
    </div>
  );
}

export default PricingSliderFormation;
