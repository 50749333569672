// src/components/MonEspace.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './MonEspace.css';

const MonEspace = () => {
  const [selectedOption, setSelectedOption] = useState('accueil');
  const navigate = useNavigate();

  const handleLogout = () => {
    // Ajoutez ici votre logique de déconnexion
    navigate('/');
  };

  return (
    <div className="mon-espace">
      <nav className="sidebar">
        <ul>
          <li>
            <button onClick={() => setSelectedOption('accueil')}>Accueil</button>
          </li>
          <li>
            <button onClick={() => setSelectedOption('messagerie')}>Messagerie</button>
          </li>
          <li>
            <button onClick={() => setSelectedOption('monPortefeuille')}>Mon portefeuille</button>
          </li>
          <li>
            <button onClick={() => setSelectedOption('mesDemandes')}>Mes demandes</button>
          </li>
          <li>
            <button onClick={() => setSelectedOption('mesAbonnements')}>Mes abonnements</button>
          </li>
          <li>
            <button onClick={() => setSelectedOption('parametres')}>Paramètres</button>
          </li>
          <li>
            <button onClick={handleLogout}>Déconnexion</button>
          </li>
        </ul>
      </nav>
      <div className="content">
        {selectedOption === 'accueil' && (
          <div className="accueil">
            <div className="watchlist">Ma Watchlist</div>
            <div className="portefeuille-oreades">Portefeuille Oreades</div>
          </div>
        )}
        {selectedOption === 'messagerie' && (
          <div className="messagerie">
            <form>
              <textarea placeholder="Écrire un message..."></textarea>
              <button type="submit">Envoyer</button>
            </form>
          </div>
        )}
        {selectedOption === 'monPortefeuille' && <div className="mon-portefeuille">Zone en cours de construction</div>}
        {selectedOption === 'mesDemandes' && <div className="mes-demandes">Zone en cours de construction</div>}
        {selectedOption === 'mesAbonnements' && <div className="mes-abonnements">Zone en cours de construction</div>}
        {selectedOption === 'parametres' && <div className="parametres">Zone en cours de construction</div>}
      </div>
    </div>
  );
};

export default MonEspace;
