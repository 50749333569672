// src/pages/Ressources.js
import React from 'react';
import Section_aid from '../components/Section_aid';
import Section_si from '../components/Section_si';

import methodesLogoF from '../assets/logo/fortuneo.webp';
import methodesLogoB from '../assets/logo/boursobank.webp';
import methodesLogoTR from '../assets/logo/trade-republic.webp';
import methodesLogoD from '../assets/logo/degiro.webp';
import methodesLogoIB from '../assets/logo/interactive-brokers.webp';
import methodesLogoT2 from '../assets/logo/t212.jpg';
import methodesImagesNewsletter from '../assets/images/capture_newsletter.png';
import methodesImagesAccesOutil from '../assets/images/googleSheets.png';

import PricingCard from '../components/PricingCard';
import PricingSliderRessources from '../components/PricingSliderRessources';

const methodesText3 = (
  <>
    <div className="methodes-part">
      <p>jusqu\'à 140 € offerts.</p>
      <h3>Code : 123456789 </h3>
    </div>
  </>
);

const methodesTextNewsletter = (
  <>
    <div className="methodes-part">
      <p>édition mensuelle.</p>
      <h3>📰 Incriptions ▶ </h3>
    </div>
  </>
);

const methodesTextAccesOutil = (
  <>
    <div className="methodes-part">
      <p> Outil de suivi de portefeuille via Google Sheets.</p>
      <h3>🖥️ Accès au Google Sheets ▶ </h3>
    </div>
  </>
);

const methodesTextTitre = (
  <>
    <div className="methodes-part">
      <p>Gagnez de l'argent ou d'autres avantages en ouvrant un compte-titre ou PEA. </p>
    </div>
  </>
);

const methodesTextTitre2 = (
  <>
    <div className="methodes-part">
      <p>Abonnez-vous dès à présent à ma newsletter afin d'être au courant de toutes les opportunités de marchés.</p>
    </div>
  </>
);

const methodesTextTitre3 = (
  <>
    <div className="methodes-part">
      <p>Accédez à l'outil de suivi de portefeuille.</p>
    </div>
  </>
);

const Ressources = () => {
  return (
    <>
      <div>
          <h2>📚 Les indispensables à connaître</h2>
          <p>Voici les 8 principales ressources incontournables gratuites et payantes que j'utilise pour investir et que tous les investisseurs recommandes.</p>
          <PricingSliderRessources />
        </div>
        <div className="footer-divider-two"></div>
        <Section_si 
            id="ressources" 
            title="📰 Newsletter"
            text={methodesTextTitre2}
          >
          <PricingCard 
              id="ressources" 
              title="La newsletter"
              text={methodesTextNewsletter}
              image={methodesImagesNewsletter}
              >
            </PricingCard>
        </Section_si>
        <Section_si 
            id="ressources" 
            title="🖥️ AccessOutil"
            text={methodesTextTitre3}
            link="https://docs.google.com/spreadsheets/d/1LMkT58D9K_4PNL34WCTi1RQ95k-CaQaMEljb_aiMbqw/edit?gid=0#gid=0"
          >
          <PricingCard 
              id="ressources" 
              title="La newsletter"
              text={methodesTextAccesOutil}
              image={methodesImagesAccesOutil}
              >
            </PricingCard>
        </Section_si>
        <div className="footer-divider-two"></div>
        <Section_si 
            id="ressources" 
            title="🎁 Ouvrez un compte"
            text={methodesTextTitre}
          >
        </Section_si>
        <div className="methodes-grid">
          <PricingCard 
              id="ressources" 
              title="Fortuneo"
              text={methodesText3}
              image={methodesLogoF}
              >
            </PricingCard>
            <PricingCard 
              id="ressources" 
              title="Boursobank"
              text={methodesText3}
              image={methodesLogoB}
              >
            </PricingCard>
            <PricingCard 
              id="ressources" 
              title="Degiro"
              text={methodesText3}
              image={methodesLogoD}
              >
            </PricingCard>
            <PricingCard 
              id="ressources" 
              title="Interactive Brokers"
              text={methodesText3}
              image={methodesLogoIB}
              >
            </PricingCard>
            <PricingCard 
              id="ressources" 
              title="Trading 212"
              text={methodesText3}
              image={methodesLogoT2}
              >
            </PricingCard>
            <PricingCard 
              id="ressources" 
              title="Trade Republic"
              text={methodesText3}
              image={methodesLogoTR}
              >
            </PricingCard>
        </div>
    </>
  );
};

export default Ressources;