// src/components/Section_ais.js
import React from 'react';
import './Section.css';
import PropTypes from 'prop-types';

const Section_aid = ({ id, title, text, image, children }) => {
  return (
    <section_ai id={id} className="section_ai">
      <h2>{title}</h2>
      <div className="section-content">
        {text && <div className="section-text">{text}</div>}
        {image && <img src={image} alt={`${title} illustration`} className="section-image" />}
      </div>
      {children}
    </section_ai>
  );
};

Section_aid.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.string,
};
export default Section_aid;
