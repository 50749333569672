// src/components/PricingSliderRessources.js
import React from 'react';
import Slider from 'react-slick';

import PricingCard from './PricingCard';
import './PricingSlider.css';

import methodesImage1 from '../assets/logo/bloom.png';
import methodesImage2 from '../assets/logo/guru.png';
import methodesImage3 from '../assets/logo/justetf-logo.png';
import methodesImage4 from '../assets/logo/seekinga.png';
import methodesImage5 from '../assets/logo/morningstar.png';
import methodesImage6 from '../assets/logo/boursedirect-logo.svg';
import methodesImage7 from '../assets/logo/zonebourse.png';
import methodesImage8 from '../assets/logo/investingcom.jpg';

function PricingSliderRessources() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="pricing-slider">
      <Slider {...settings}>
        <PricingCard
          id="methode1"
          title="Bloomberg"
          text="La revue financière que tout Wall Street utilise"
          image={methodesImage1}
        />
        <PricingCard
          id="methode2"
          title="GuruFocus"
          text="Le screener le plus complet"
          image={methodesImage2}
        />
        <PricingCard
          id="methode3"
          title="Just ETF"
          text="Le leader pour les stratégies d'ETF"
          image={methodesImage3}
          link="https://www.justetf.com/fr/"
        />
        <PricingCard
          id="methode4"
          title="Seaking Alpha"
          text="Le top des screeners gratuits"
          image={methodesImage4}
        />
        <PricingCard
          id="methode5"
          title="Morningstar"
          text="Les information financière les plus précises"
          image={methodesImage5}
        />
        <PricingCard
          id="methode6"
          title="Bourse Direct"
          text="Le courtier le plus fiable"
          image={methodesImage6}
        />
        <PricingCard
          id="methode6"
          title="Zone Bourse"
          text="Le site incontournable lorsqu'on investit "
          image={methodesImage7}
        />
        <PricingCard
          id="methode6"
          title="Investing.com"
          text="Pour ne jamais rater un événement majeur du calendrier économique"
          image={methodesImage8}
        />
      </Slider>
    </div>
  );
}

export default PricingSliderRessources;
