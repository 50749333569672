// src/pages/MentionsLegales.js
import React from 'react';
import Section_si from '../components/Section_si';

const methodesTextMLT = (
  <>
    <div className="methodes-part">
      <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Hol'Don l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
    </div>
  </>
);

const methodesTextMLC = (
  <>
    <div className="methodes-part">
      <p>Site édité par : Hol'Don</p>
      <p>Adresse : 4 Rue coeur de chauffe, 97351 Matoury</p>
      <p>Téléphone : +33764405363</p>
      <p>Hébergement : Heroku</p>
    </div>
  </>
);

const MentionsLegales = () => {
  return (
    <>
        <Section_si 
          id="mentionslegales" 
          title="Mentions Légales"
          text={methodesTextMLT}
          >
        </Section_si>
        <Section_si 
          id="mentionslegales" 
          title="Edition du site"
          text={methodesTextMLC}
          >
        </Section_si>
      </>
  );
};

export default MentionsLegales;
